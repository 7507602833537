import Vue from 'vue';
import VueRouter from 'vue-router'
import store from './store'

import auth from '@/modules/auth/index.js';
import events from '@/modules/events/index.js';
import users from '@/modules/users/index.js';
import planificacion from '@/modules/planificacion/index.js';
import costes from '@/modules/costes/index.js';
import plantilla from '@/modules/adhoc/index.js';
import jornadas from '@/modules/jornadas/index.js';
import horas from '@/modules/horas/index.js';
import necesidades from '@/modules/necesidades/index.js';
import criticas from '@/modules/criticas/index.js';
import pendientes from '@/modules/pendientes/index.js';
import presupuestos from '@/modules/presupuestos/index.js';
import citas from '@/modules/citas/index.js';
import horasMensuales from '@/modules/horasMensuales/index.js';
import checkCatering from '@/modules/checkCatering/index.js';
import escandallo from '@/modules/escandallo/index.js';
import informes from '@/modules/informes/index.js';
import pricing from '@/modules/pricing/index.js';
import avgBodas from '@/modules/avgBodas/index.js';
import kpi from '@/modules/kpi/index.js';
import comercial from '@/modules/comercial/index.js';
import aproximaciones from '@/modules/aproximaciones/index.js';
import perfilEquipo from '@/modules/perfilEquipo/index.js';
import disponibilidadEquipo from '@/modules/disponibilidadEquipo/index.js';
import documentosEquipo from '@/modules/documentosEquipo/index.js';
import eventosEquipo from '@/modules/eventosEquipo/index.js';
import fichajeEquipo from '@/modules/fichajeEquipo/index.js';
import contactoEquipo from '@/modules/contactoEquipo/index.js';
import tapas from '@/modules/tapas/index.js';
import tareas from '@/modules/tareas/index.js';
import competencia from '@/modules/competencia/index.js';
import pbs from '@/modules/pruebasBoda/index.js';

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'Index',
		component: () => import('@/views/Index.vue'),
		meta: { requiresAuth: true, acceso:["EVENTOS"] }
	},
	{
		path: '/portal_equipo',
		name: 'IndexExtra',
		component: () => import('@/views/IndexExtra.vue'),
		meta: { requiresAuthExtra: true }
	},
	auth.router,
	events.router,
	users.router,
	planificacion.router,
	costes.router,
	plantilla.router,
	jornadas.router,
	horas.router,
	necesidades.router,
	criticas.router,
	pendientes.router,
	presupuestos.router,
	citas.router,
	horasMensuales.router,
	checkCatering.router,
	escandallo.router,
	informes.router,
	pricing.router,
	avgBodas.router,
	kpi.router,
	comercial.router,
	aproximaciones.router,
	perfilEquipo.router,
	disponibilidadEquipo.router,
	documentosEquipo.router,
	eventosEquipo.router,
	fichajeEquipo.router,
	contactoEquipo.router,
	tapas.router,
	tareas.router,
	competencia.router,
	pbs.router,
	{
		path: '/*',
		redirect: '/'
	}
]
const router = new VueRouter({
	routes,
	mode: "history",
	base: process.env.BASE_URL,
})

router.beforeEach(async (to, from, next) => {
	var skip = false
	// REQUIERE CUALQUIERA
	if (
		// la ruta necesita login de extra
		(to.matched.some((record) => record.meta.requiresAuthBoth)) &&
		(store.getters.getTokenExtra === null) &&
		(store.getters.getToken === null)
	) {
		// let msg = store.getters.getToken != null ? "La sesión ha expirado" : "";
		await store.dispatch("logout");
		await store.dispatch("logoutExtra");
		next({ name: "Login", query: { redirect: to.path, } });
		return
	}
	else{
		skip = true
	}

	// REQUIERE USUARIO GESTOR
	if (
		// la ruta necesita un login de gestor
		to.matched.some((record) => record.meta.requiresAuth) &&
		// no ha iniciado sesion en gestor
		(store.getters.getToken === null || store.getters.getPermisos === null)
	) {
		// let msg = store.getters.getToken != null ? "La sesión ha expirado" : "";
		await store.dispatch("logout");
		next({ name: "Login", query: { redirect: to.path, } });
		return
	}

	// REQUIERE USUARIO EXTRA
	if (
		// la ruta necesita login de extra
		(to.matched.some((record) => record.meta.requiresAuthExtra)) &&
		// no ha iniciado sesion en extra
		(store.getters.getTokenExtra === null)
	) {
		// let msg = store.getters.getToken != null ? "La sesión ha expirado" : "";
		await store.dispatch("logoutExtra");
		next({ name: "LoginExtra", query: { redirect: to.path, } });
		return
	}

	if (skip){
		next()
		return
	}
	else if (to.matched.some((record) => record.meta.acceso)) {
		const acceso = to.matched.find((record) => record.meta.acceso).meta.acceso;
		if ( !!( store.getters.getTokenPayload.permisos & store.getters.getPermisos[acceso] ) ) {
			next();
			return
		}
		else {
			next({ name: "Index" });
			return
		}
	}
	
	next();
	return
});

export default router;