<template>
  <v-container fluid class="pa-0">
    <v-row v-if="!loading">
      <v-col cols="12">
        <div class="d-flex">
          <v-spacer></v-spacer>
          <v-row class="justify-center align-center">
            <v-btn icon fab @click="getDisponibilidad(-1)"><v-icon>mdi-chevron-left</v-icon></v-btn>
            <h1 style="width: 200px;" class="text-center">
              {{ new Date(date).toLocaleDateString('esp', { month: 'long' }).toLocaleUpperCase() }}
            </h1>
            <v-btn icon fab @click="getDisponibilidad(1)"><v-icon>mdi-chevron-right</v-icon></v-btn>
          </v-row>
          <v-spacer></v-spacer>
          <v-btn v-if="calendar" color="info" @click.stop="saveEstado">
            <v-icon left> mdi-floppy </v-icon>
            Guardar
          </v-btn>
          <v-btn v-else color="info" @click.stop="createCat">
            <v-icon left> mdi-shape </v-icon>
            Categorías
          </v-btn>
          <v-btn :color="calendar ? 'primary' : 'success'" class="mx-5" @click.stop="calendar = !calendar">
            <v-icon left> {{ calendar ? 'mdi-account-group' : 'mdi-calendar' }} </v-icon>
            {{ calendar ? 'Gestión' : 'Disponibilidad' }}
          </v-btn>
          <v-btn color="primary" @click.stop="selected = { new: 1 }">
            <v-icon left> mdi-plus </v-icon>
            Nuevo
          </v-btn>
        </div>
      </v-col>
      <v-col v-if="calendar" cols="12" class="py-0">
        <v-simple-table class="fixed" style="max-width: min-content !important" v-if="!loadingCal">
          <thead>
            <tr>
              <th style="width:400px;" rowspan="3" colspan="2"><v-text-field hide-details="" class="py-0" v-model="searchUnidad" placeholder="NOMBRE"></v-text-field></th>
              <th style="width:200px;border-bottom:1px solid var(--v-secondary-base);height:35px;">DÍA</th>
              <th @click.stop="goToPlanificacion(d)" class="px-0 text-center" :style="'border-bottom:1px solid var(--v-secondary-base);cursor:pointer;height:35px;width: 35px !important;font-size: large;' + ([6,0].includes(new Date(new Date(date).getFullYear(),new Date(date).getMonth(),d).getDay()) ? 'font-weight: bolder' : 'font-weight:lighter')" v-for="d in new Date(new Date(date).getFullYear(),new Date(date).getMonth()+1,0).getDate()">
                {{ ('0'+d).slice(-2) }}
              </th>
            </tr>
            <tr>
              <th style="width:min-content;height:35px">EXTRAS NECESARIOS</th>
              <th class="px-0 text-center" :style="'height:35px;width: 35px !important;font-size:large;' + ((extras.find(e => e.fecha == (new Date(date).getFullYear() + '-' + ('0'+(new Date(date).getMonth()+1)).slice(-2) + '-' + ('0'+d).slice(-2)))?.necesarios || 0) > 0 ? 'color:var(--v-info-base)' : '')" v-for="d in new Date(new Date(date).getFullYear(),new Date(date).getMonth()+1,0).getDate()">
                {{ extras.find(e => e.fecha == (new Date(date).getFullYear() + '-' + ('0'+(new Date(date).getMonth()+1)).slice(-2) + '-' + ('0'+d).slice(-2)))?.necesarios || 0 }}
              </th>
            </tr>
            <tr>
              <th style="height:35px;width: 150px !important">TOTAL CONFIRMADOS</th>
              <th class="px-0 text-center" :style="'height:35px;width: 35px !important;font-size:large;' + ((modelo.filter(e => e.date == (new Date(date).getFullYear() + '-' + ('0'+(new Date(date).getMonth()+1)).slice(-2) + '-' + ('0'+d).slice(-2)) && e.idEstadoUnidadesEquipo == 3).length - (extras.find(e => e.fecha == (new Date(date).getFullYear() + '-' + ('0'+(new Date(date).getMonth()+1)).slice(-2) + '-' + ('0'+d).slice(-2)))?.necesarios || 0)) >= 0 ? 'color:var(--v-info-base)' : 'color:var(--v-error-base)')" v-for="d in new Date(new Date(date).getFullYear(),new Date(date).getMonth()+1,0).getDate()">
                {{ modelo.filter(e => e.date == (new Date(date).getFullYear() + '-' + ('0'+(new Date(date).getMonth()+1)).slice(-2) + '-' + ('0'+d).slice(-2)) && e.idEstadoUnidadesEquipo == 3).length }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="unidad in equipoCom">
              <td class="py-0" style="width:300px; height: 35px !important;cursor: pointer;white-space:nowrap;overflow:hidden;text-overflow: ellipsis" @click.stop="selectItem(unidad)" :title="unidad.nombre + ' ' + (unidad.apellidos || unidad.inicialesApellidos || '')">{{ unidad.nombre + ' ' + (unidad.apellidos || unidad.inicialesApellidos || '') }}</td>
              <td class="py-0" style="width:100px; height: 35px !important;">{{ unidad.dias_trabajados }}</td>
              <td class="py-0" style="height: 35px !important;" :title="unidad.nombreCategoria">
                <v-chip :color="unidad.color" class="ml-1" outlined label>
                {{ unidad.nombreCategoria }}
                </v-chip>
              </td>
              <td @contextmenu.prevent="openMenu(unidad.idUnidadEquipo, d)" :title="modelo.find(mod => mod.idUnidadEquipo == unidad.idUnidadEquipo && mod.date == (new Date(date).getFullYear()+'-'+('0'+(new Date(date).getMonth()+1)).slice(-2)+'-'+('0'+d).slice(-2)))?.observaciones || ''" :class="'day pa-0 primary--text ' + (getCellClass(unidad.idUnidadEquipo, d))" v-for="d in new Date(new Date(date).getFullYear(),new Date(date).getMonth()+1,0).getDate()" @click.stop="changeEstado(d,unidad.idUnidadEquipo)" :style="'width:35px;height:35px;text-align:center;border-bottom:thin solid rgba(255, 255, 255, 0.12);border-right:thin solid rgba(255, 255, 255, 0.12);background-color:' + [null,null,null,'var(--v-success-base)','var(--v-error-base)','var(--v-warning-base)','gray'][modelo.find(mod => mod.idUnidadEquipo == unidad.idUnidadEquipo && mod.date == (new Date(date).getFullYear()+'-'+('0'+(new Date(date).getMonth()+1)).slice(-2)+'-'+('0'+d).slice(-2)))?.idEstadoUnidadesEquipo || 6]">
                <v-icon v-if="modelo.find(mod => mod.idUnidadEquipo == unidad.idUnidadEquipo && mod.date == (new Date(date).getFullYear()+'-'+('0'+(new Date(date).getMonth()+1)).slice(-2)+'-'+('0'+d).slice(-2)))?.observaciones" :class="$vuetify.theme.dark ? 'white--text' : 'black--text'">mdi-account-voice</v-icon>
              </td>
            </tr>
            <tr style="height: 30px">
              <td style="text-align:right" :colspan="2 + new Date(new Date(date).getFullYear(),new Date(date).getMonth()+1,0).getDate()">
                <v-btn small icon fab @click.stop="page--"><v-icon>mdi-arrow-left</v-icon></v-btn>{{ Math.min(Math.max(page,0),Math.ceil(equipo.length / 25)) }}<v-btn small icon fab @click.stop="page++"><v-icon>mdi-arrow-right</v-icon></v-btn>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
      <v-col v-else cols="12" class="pt-0">
        <v-data-table
          fixed-header
          :items="equipo"
          :headers="equipoHeader"
          class="fixed-checkbox"
          item-key="idEvento"
          :single-select="false"
          show-select
          :loading="loading"
          :footer-props="{
            showFirstLastPage: true,
            showCurrentPage: true,
            itemsPerPageOptions: [25, 50, -1],
          }"
          checkbox-color="secondary"
        >
          <template v-slot:body.prepend="{ headers }">
            <table-filters
              :headers="headers"
              :items="equipo"
              v-model="inlineFilters"
            ></table-filters>
          </template>

          <template v-slot:item.nombreCategoria="{ item }">
            <v-chip :color="item.color" class="ml-1" outlined label>
              {{ item.nombreCategoria }}
            </v-chip>
          </template>

          <template v-if="(!!($store.getters.getTokenPayload?.permisos & $store.getters.getPermisos['CONTROL_HORAS']) && !!($store.getters.getTokenPayload?.permisos & $store.getters.getPermisos['EVENTOS']))" v-slot:item.contratacion="{ item }">
              {{ item.contratacion }}
          </template>
          <template v-if="(!!($store.getters.getTokenPayload?.permisos & $store.getters.getPermisos['CONTROL_HORAS']) && !!($store.getters.getTokenPayload?.permisos & $store.getters.getPermisos['EVENTOS']))" v-slot:item.pEvento="{ item }">
              {{ item.pEvento }}
          </template>
          <template v-if="(!!($store.getters.getTokenPayload?.permisos & $store.getters.getPermisos['CONTROL_HORAS']) && !!($store.getters.getTokenPayload?.permisos & $store.getters.getPermisos['EVENTOS']))" v-slot:item.pEventoTop="{ item }">
              {{ item.pEventoTop }}
          </template>
          <template v-if="(!!($store.getters.getTokenPayload?.permisos & $store.getters.getPermisos['CONTROL_HORAS']) && !!($store.getters.getTokenPayload?.permisos & $store.getters.getPermisos['EVENTOS']))" v-slot:item.pMontaje="{ item }">
              {{ item.pMontaje }}
          </template>

          <template v-slot:item.horasMes="{ item }">
            <div class="d-flex">
              <v-btn
                :to="`/horas_mensuales/${item.idUnidadEquipo}`"
                small
                rounded
                color="success"
              >
                <v-icon left>mdi-clock</v-icon>
                Horas Mensuales
              </v-btn>
            </div>
          </template>

          <template v-slot:item.acciones="{ item }">
            <div class="d-flex">
              <v-btn
                @click.stop="selectItem(item)"
                small
                rounded
                color="secondary"
              >
                <v-icon left>mdi-pencil</v-icon>
                Editar
              </v-btn>
              <v-btn
                @click.stop="eliminar(item)"
                small
                class="ml-1"
                rounded
                color="error"
              >
                <v-icon left>mdi-delete</v-icon>
                Eliminar
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    
    <v-dialog
      :value="eventos.length"
      @input="(v) => (v === false ? (eventos = []) : null)"
      max-width="1000"
      persistent
    >
      <EventosEquipo
        @close="eventos = []"
        @saved="getEventosEquipo"
        :key="keyHelper"
        :empleado="selectedEmpleado"
        :eventos="eventos"
        :fecha="selectedDate"
      />
    </v-dialog>

    <v-dialog
      :value="selected && Object.keys(selected).length"
      @input="(v) => (v === false ? deselect() : null)"
      max-width="800"
      persistent
    >
      <NuevoEquipo
        @reload="getEquipo()"
        @close="deselect()"
        :key="keyHelper"
        :value="selected"
      />
    </v-dialog>

    <ConfirmDialog ref="confirm" />
    <Categorias ref="categorias" />
  </v-container>
</template>

<script>
import { parseDate, perColumnFilter } from "@/utils/index.js";

export default {
  components: {
    TableFilters: () => import("@/components/TableFilters.vue"),
    ConfirmDialog: () => import("@/components/ConfirmDialog.vue"),
    Categorias: () => import("../components/categorias/Categorias.vue"),
    NuevoEquipo: () => import("../components/NuevoEquipo.vue"),
    EventosEquipo: () => import("../components/EventosEquipo.vue"),
  },
  data() {
    return {
      page: 0,
      equipo: [],
      changed: false,
      modelo:[],
      extras:[],
      eventos:[],
      eventosEquipo:[],
      selected: {},
      selectedEmpleado: {},
      selectedDate: '',
      inlineFilters: {},
      searchUnidad: '',
      keyHelper: 0,
      loading: false,
      loadingCal: false,
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      calendar: true,
    };
  },
  computed:{
    equipoCom(){
      let equ = this.equipo.filter(eq => (![11,18].includes(eq.idCategoriaEquipo) || eq.extra))
      if (this.searchUnidad) return equ.filter(eq => (eq.nombre.toLowerCase().replaceAll(' ', '').includes(this.searchUnidad.toLowerCase().replaceAll(' ', ''))))
      return equ.slice((Math.min(Math.max(this.page,0),Math.ceil(equ.length / 25))*25),(Math.min(Math.max(this.page,0),Math.ceil(equ.length / 25))*25)+25)
    },
    equipoHeader(){
      var a = [
        {
          text: "Nombre",
          value: "nombre",
          cellClass: "pa-1 text-no-wrap font-weight-bold",
        },
        {
          text: "Apellidos",
          value: "apellidos",
          cellClass: "pa-1 text-no-wrap font-weight-bold",
        },
        { text: "Prioridad", value: "prioridad" },
        { text: "Disponibilidad", value: "disponibilidad" },
        { text: "Experiencia", value: "experiencia" },
        { text: "Años", value: "años" },
        { text: "Categoria", value: "nombreCategoria", dataType: "select" },
        ]
        if (!!(this.$store.getters.getTokenPayload?.permisos & this.$store.getters.getPermisos['CONTROL_HORAS']) && !!(this.$store.getters.getTokenPayload?.permisos & this.$store.getters.getPermisos['EVENTOS'])){
          a.push({ text: "Contratación", value: "contratacion", dataType: "select" })
          a.push({ text: "P. Evento", value: "pEvento", dataType: "select" })
          a.push({ text: "P. Top", value: "pEventoTop", dataType: "select" })
          a.push({ text: "P. Montaje", value: "pMontaje", dataType: "select" })
          a.push({ text: "Horas Mensuales", value: "horasMes", sortable: false, filterable: false })
          a.push({ text: "Acciones", value: "acciones", sortable: false, filterable: false })
        }
      return a.map((header) => ({
        class: "text-no-wrap sticky-header",
        cellClass: "pa-1 text-no-wrap",
        filter: (value) =>
          perColumnFilter(
            value,
            this.inlineFilters[header.value],
            header.dataType
          ),
        dataType: "text",
        ...header,
      }))
    }
  },
  async mounted() {
    this.loading = true
    await Promise.all([
      this.getEquipo(),
      this.getEventosEquipo(),
      this.getDisponibilidad(),
      this.getExtras()
    ])
    this.loading = false
  },
  methods: {
    getCellClass(id, d){
      if (id in this.eventosEquipo){
        return this.eventosEquipo[id][d-1].conf ? 'conf' : ''
      }
      else 
        return ''
    },
    async getEventosEquipo(){
      this.eventos = []
      const { data } = await axios({
        url: `/planificacion/eventosEquipo/${(new Date(this.date).getFullYear() + '-' + ('0'+(new Date(this.date).getMonth()+1)).slice(-2))}`,
      });
      this.$set(this.$data, 'eventosEquipo', data)
    },
    async openMenu(idUnidadEquipo, d){
      const { data } = await axios({
        url: `/planificacion/single/${(new Date(this.date).getFullYear() + '-' + ('0'+(new Date(this.date).getMonth()+1)).slice(-2) + '-' + ('0'+d).slice(-2))}/${idUnidadEquipo}`,
      });
      this.selectedDate = (new Date(this.date).getFullYear() + '-' + ('0'+(new Date(this.date).getMonth()+1)).slice(-2) + '-' + ('0'+d).slice(-2))
      this.selectedEmpleado = this.equipo.find(e => e.idUnidadEquipo == idUnidadEquipo)
      this.eventos = data
      if(this.eventos.length == 0) this.$root.$emit("snack", "No se han asignado eventos a esta persona.")
    },
    goToPlanificacion(d){
      this.$router.push({path: '/planificacion/' + (new Date(this.date).getFullYear() + '-' + ('0'+(new Date(this.date).getMonth()+1)).slice(-2) + '-' + ('0'+d).slice(-2))})
    },
    async getExtras(){
      const { data } = await axios({
        url: `/planificacion/mensual/${new Date(this.date).getFullYear() + '-' + ('0'+(new Date(this.date).getMonth()+1)).slice(-2) +'-'+('0'+new Date(this.date).getDate()).slice(-2)}`,
      });
      var arr = [...new Set(data.map(x => x.fecha))]
      arr.forEach(dia => {
        this.extras.some(ex => ex.fecha == dia) ? (this.extras.find(ex => ex.fecha == dia).necesarios = data.filter(d => d.fecha == dia).reduce((acc,sum) => acc + Math.ceil(sum.nAdultos/(sum.dist || 1)),0)) : (this.extras.push({ fecha: dia, necesarios: data.filter(d => d.fecha == dia).reduce((acc,sum) => acc + Math.ceil(sum.nAdultos/(sum.dist || 1)),0), confirmados: this.modelo.filter(mod => mod.fecha == dia && mod.idEstadoUnidadesEquipo == 3).length }))
      });
    },
    changeEstado(d,idUnidadEquipo){
      this.changed = true
      if (this.modelo.some(mod => mod.idUnidadEquipo == idUnidadEquipo && mod.date == (new Date(this.date).getFullYear()+'-'+('0'+(new Date(this.date).getMonth()+1)).slice(-2)+'-'+('0'+d).slice(-2)))) {
        var oldState = this.modelo.find(mod => mod.idUnidadEquipo == idUnidadEquipo && mod.date == (new Date(this.date).getFullYear()+'-'+('0'+(new Date(this.date).getMonth()+1)).slice(-2)+'-'+('0'+d).slice(-2))).idEstadoUnidadesEquipo
        var newState = undefined
        if (oldState == 5) newState = 3
        else if (oldState == 3) newState = 4
        else if (oldState == 4) newState = 6
        else if (oldState == 6) newState = 5
        this.$set(this.modelo.find(mod => mod.idUnidadEquipo == idUnidadEquipo && mod.date == (new Date(this.date).getFullYear()+'-'+('0'+(new Date(this.date).getMonth()+1)).slice(-2)+'-'+('0'+d).slice(-2))),'idEstadoUnidadesEquipo', newState)
        this.$set(this.modelo.find(mod => mod.idUnidadEquipo == idUnidadEquipo && mod.date == (new Date(this.date).getFullYear()+'-'+('0'+(new Date(this.date).getMonth()+1)).slice(-2)+'-'+('0'+d).slice(-2))),'changed', true)
      }
      else {
        this.modelo.push({date: (new Date(this.date).getFullYear()+'-'+('0'+(new Date(this.date).getMonth()+1)).slice(-2)+'-'+('0'+d).slice(-2)), idEstadoUnidadesEquipo : 5, idUnidadEquipo : idUnidadEquipo, changed: 'true'})
      }
    },
    parseDate,
    async getEquipo() {
      this.keyHelper++;
      const { data } = await axios({
        url: "/unidades_equipo",
      });
      this.equipo = data;
    },
    async getDisponibilidad(move = 0) {
      this.loadingCal = true
      if (this.changed){
        const conf = await this.$refs.confirm.open(
          "¿Quieres guardar los cambios?",
          "¿Guardar cambios antes de cambiar el mes?",
          { color: "primary" }
        )
        if (conf) {
          try{
            await axios({
              method: 'POST',
              url: `${process.env.VUE_APP_API_URL}/disponibilidad/estados/${(new Date(this.date).getMonth()+1+move)}`,
              data: this.modelo.filter(mod => mod.changed)
            })
            this.$root.$emit("snack", "Se han guardado los cambios correctamente");
            this.changed = false;
          } catch (err) {
            this.saving = false;
            this.$root.$emit("snack", "No se han podido guardar los cambios");
            return
          }
        }
      }
      this.date = new Date(new Date(this.date).getFullYear(), new Date(this.date).getMonth()+move,1);
      this.getEventosEquipo()
      this.getExtras()
      this.keyHelper++;
      const { data } = await axios({
        url: "/disponibilidad/estados/" + (new Date(this.date).getMonth()+1),
      });
      this.modelo = data;
      this.changed = false;
      this.loadingCal = false
    },
    async saveEstado(){
      try{
        await axios({
          method: 'POST',
          url: `${process.env.VUE_APP_API_URL}/disponibilidad/estados/${(new Date(this.date).getMonth()+1)}`,
          data: this.modelo.filter(mod => mod.changed)
        })
        this.$root.$emit("snack", "Se han guardado los cambios correctamente");
        this.changed = false;
      } catch (err) {
        this.saving = false;
        this.$root.$emit("snack", "No se han podido guardar los cambios");
      }
    },
    async eliminar({ idUnidadEquipo, nombre_completo }) {
      const confirmation = await this.$refs.confirm.open(
        `Eliminar ${nombre_completo}`,
        `¿Estás seguro de que quieres eliminar esta unidad?`,
        { color: "error" }
      );
      if (!confirmation) return;
      const { data } = await axios({
        method: "DELETE",
        url: "/unidades_equipo/" + idUnidadEquipo,
      });
      this.getEquipo();
    },
    deselect() {
      this.$set(this.$data, "selected", {});
			this.keyHelper += 1;
    },
    selectItem(item) {
      this.$set(this.$data, "selected", item);
			this.keyHelper += 1;
    },
    createCat(){
      this.$refs.categorias.open()
    }
  },
};
</script>

<style>
.day:hover{
  z-index:1000;
  border: none !important;
  -webkit-box-shadow: inset 0px 0px 5px 1px rgba(0,0,0,0.75) !important;
  -moz-box-shadow: inset 0px 0px 5px 1px rgba(0,0,0,0.75) !important;
  box-shadow: inset 0px 0px 5px 1px rgba(0,0,0,0.75) !important;
  cursor:pointer
}
.fixed table{
  table-layout: fixed
}

td.conf {
  position: relative;
  padding: 10px;
  text-align: center;
}
td.conf::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, transparent 49%, black 49%, black 51%, transparent 51%);
  pointer-events: none; /* Make sure the line doesn't interfere with clicking or interacting */
}
</style>