<template>
  <v-dialog persistent v-model="dialog" :max-width="options.width" :style="{ zIndex: 20000 }" @keydown.esc="cancel">
    <v-card :elevation="2">
      <v-card-text class="py-3">
        <v-form>
          <template v-if="page == 1">
            <h1 class="primary--text mb-5">PAREJA</h1>
            <v-row>
              <v-col cols="12" md="6">
                <h2 class="primary--text">DATOS</h2>
                <div class="ms-5">
                  <v-text-field class="mb-4 mt-3" dense label="NOMBRE PAREJA" hide-details="" v-model="pb.novios"></v-text-field>
                  <v-text-field class="mb-4 mt-3" dense label="FECHA ENLACE" hide-details="" v-model="pb.fechaBoda"></v-text-field>
                  <h3 class="primary--text">ESPACIOS</h3>
                  <v-text-field class="mb-4 ms-5 mt-3" dense label="APERITIVO" hide-details="" v-model="pb.aptvo"></v-text-field>
                  <v-text-field class="mb-4 ms-5 mt-3" dense label="BANQUETE" hide-details="" v-model="pb.banquete"></v-text-field>
                </div>
              </v-col>
              <v-col cols="12" md="6">
                <h2 class="primary--text">PRUEBA DE BODA</h2>
                <div class="ms-5">
                  <v-text-field class="mb-4 mt-3" dense label="FECHA DEGUSTACIÓN" hide-details="" v-model="pb.fechaDegustacion"></v-text-field>
                  <v-text-field class="mb-4 mt-3" dense label="Nº ADULTOS" hide-details="" v-model="pb.adultos"></v-text-field>
                  <v-textarea filled dense label="INTOLERANCIAS" hide-details="" v-model="pb.novios"></v-textarea>
                </div>
              </v-col>
            </v-row>
            <v-divider class="my-2"></v-divider>
            <v-row>
              <v-col cols="12" md="6">
                <h2 class="primary--text">PERSONAS BODA</h2>
                <div class="ms-5">
                  <v-text-field class="mb-4 mt-3" dense label="ADULTOS" hide-details="" v-model="pb.adultosBoda"></v-text-field>
                  <v-text-field class="mb-4 mt-3" dense label="NIÑOS" hide-details="" v-model="pb.ninosBoda"></v-text-field>
                </div>
              </v-col>
              <v-col cols="12" md="6">
                <h2 class="primary--text">HORARIO</h2>
                <div class="ms-5">
                  <v-text-field class="mb-4 mt-3" dense label="ENLACE" hide-details="" v-model="pb.hEnlace"></v-text-field>
                  <v-text-field class="mb-4 mt-3" dense label="APERITIVO" hide-details="" v-model="pb.hAptvo"></v-text-field>
                  <v-text-field class="mb-4 mt-3" dense label="COMIDA" hide-details="" v-model="pb.hComida"></v-text-field>
                  <v-text-field class="mb-4 mt-3" dense label="FIN EVENTO" hide-details="" v-model="pb.hFinEvento"></v-text-field>
                </div>
              </v-col>
            </v-row>
            <v-divider class="my-2"></v-divider>
            <v-row>
              <v-col cols="12">
                <h2 class="primary--text">ENLACE & NECESIDADES</h2>
                <v-radio-group
                  hide-details=""
                  v-model="pb.enlace"
                  column
                >
                  <div class="ms-5">
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-radio hide-details="" :value="1" label="ENLACE RELIGIOSO"></v-radio>
                        <div v-if="pb.enlace == 1">
                          <v-text-field class="mb-4 mt-3" dense label="IGLESIA" hide-details="" v-model="pb.eIglesia"></v-text-field>
                          <v-text-field class="mb-4 mt-3" dense label="POBLACIÓN" hide-details="" v-model="pb.ePoblacion"></v-text-field>
                        </div>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-radio hide-details="" :value="2" label="ENLACE CIVIL"></v-radio>
                        <v-text-field class="mb-4 mt-3" v-if="pb.enlace == 2" dense label="UBICACIÓN" hide-details="" v-model="pb.eUbicacion"></v-text-field>
                      </v-col>
                    </v-row>
                  </div>
                </v-radio-group>
                <div class="ms-5">
                  <h3 class="primary--text mt-3">GETIÓN MASFARRÉ</h3>
                  <div class="ms-5">
                    <v-checkbox hide-details="" label="SILLAS" v-model="pb.mfSillas"></v-checkbox>
                    <v-checkbox hide-details="" label="MEGAFONÍA" v-model="pb.mfMegafonia"></v-checkbox>
                    <v-checkbox hide-details="" label="BEBIDA" v-model="pb.mfBebida"></v-checkbox>
                    <v-textarea filled v-if="pb.mfBebida" dense label="DETALLAR BEBIDA" hide-details="" v-model="pb.mfBebidaDetallada"></v-textarea>
                    <v-checkbox hide-details="" label="OTRAS" v-model="pb.mfOtras"></v-checkbox>
                    <v-textarea filled v-if="pb.mfOtras" dense label="DETALLAR OTRAS" hide-details="" v-model="pb.mfBebidaDetallada"></v-textarea>
                    <v-checkbox hide-details="" label="COORDINAR RESTO DE GREMIOS" v-model="pb.mfCoordinar"></v-checkbox>
                  </div>
                </div>
              </v-col>
              <v-col cols="12">
                <h2 class="primary--text">OBSERVACIONES</h2>
                <div class="ms-5">
                  <v-textarea filled dense hide-details="" v-model="pb.obs"></v-textarea>
                </div>
              </v-col>
            </v-row>
          </template>
          <template v-if="page == 2">
            <template v-for="(p,i) in productos">
              <!-- <template v-for="(p,i) in productos.filter(pr => pr.espacios.includes(pb.tipoBoda))"> -->
              <h1 class="primary--text" v-if="i == 0 || p.superFamilia != productos[i-1].superFamilia">{{ superFamilias.find(fam => fam.id == p.superFamilia).nombre }}</h1>
              <h2 class="primary--text" v-if="i == 0 || p.idFamilia != productos[i-1].idFamilia">{{ familias.find(fam => fam.idFamilia == p.idFamilia).nombreFamilia }}</h2>
              <div class="d-flex">
                <v-checkbox class="mt-0" hide-details="" :label="p.nombreProducto" v-model="pb[p.key]"></v-checkbox>
              </div>
            </template>
          </template>
          <template v-if="page == 3">
            <h1 class="primary--text mb-5">APERITIVO</h1>
            <v-row>
              <v-col cols="12" md="6">
                <h2 class="primary--text">RECEPCIÓN</h2>
                <v-checkbox class="mt-0" hide-details="" label="Cava de bienvenida" v-model="pb.cavaBienvenida"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Limonada casera con menta" v-model="pb.limonadaCasera"></v-checkbox>
                <v-divider class="mb-3"></v-divider>
                <h2 class="primary--text">BUFFETS DE BIENVENIDA</h2>
                <h4 class="primery--text">(ELEGIR 1 O 2 - BUFFET COMPLETO)</h4>
                <v-checkbox class="mt-0" hide-details="" label="JAMONCITO DEL BUENO" v-model="pb.buffetJamon"></v-checkbox>
                <div class="ms-8">
                  <h4 class="info--text">Jamón Ibérico de Guijuelo al corte</h4>
                  <h4 class="info--text">Pan cristal & tomate rosa</h4>
                </div>
                <v-checkbox class="mt-0" hide-details="" label="LA CHARCUTERÍA" v-model="pb.buffetCharcuteria"></v-checkbox>
                <div class="ms-8">
                  <h4 class="info--text">Chorizo picante de León</h4>
                  <h4 class="info--text">Secallona de Vic</h4>
                  <h4 class="info--text">Lomo ibérico</h4>
                  <h4 class="info--text">Cabeza de jabalí - bull negro</h4>
                  <h4 class="info--text">Pan de payes y AOVE del Somontano</h4>
                </div>
                <v-checkbox class="mt-0" hide-details="" label="LA QUESERÍA" v-model="pb.buffetQuesos"></v-checkbox>
                <div class="ms-8">
                  <h4 class="info--text">Degustación de quesos de la zona</h4>
                  <h4 class="info--text">Mermeladas & frutos silvestres</h4>
                  <h4 class="info--text">Miller's toast - grissinis - picos campero</h4>
                </div>
                <v-checkbox class="mt-0" hide-details="" label="JAPONÉS" v-model="pb.buffetJapones"></v-checkbox>
                <div class="ms-8">
                  <h4 class="info--text">SushiRolls - hosomakis - nigiris - sashimi moriawase</h4>
                  <h4 class="info--text">Ensalada de algas y sésamo</h4>
                  <h4 class="info--text">Soja - wasabi - jengib</h4>
                </div>
                <v-checkbox class="mt-0" hide-details="" label="PATO" v-model="pb.buffetPato"></v-checkbox>
                <div class="ms-8">
                  <h4 class="info--text">Mil hojas de foie, membrillo, queso y manzana</h4>
                  <h4 class="info--text">Paté de campaña a las finas hierbas</h4>
                  <h4 class="info--text">Confitura de violetas - pan Guitatu - uvas</h4>
                </div>
                <v-checkbox class="mt-0" hide-details="" label="VERMUTERÍA" v-model="pb.buffetVermut"></v-checkbox>
                <div class="ms-8">
                  <h4 class="info--text">Chips & snacks</h4>
                  <h4 class="info--text">Olivas chupadedos & olivas arrugadas de Aragón</h4>
                  <h4 class="info--text">Encurtidos & Laterío "Barrecha"</h4>
                  <h4 class="info--text">Vermut casero macerado con naranja</h4>
                </div>
                <v-divider class="mb-3"></v-divider>
                <h2 class="primary--text">TEMÁTICOS</h2>
                <h4 class="primery--text">(ELEGIR 1 O 2 - BUFFET COMPLETO)</h4>
                <v-checkbox class="mt-0" hide-details="" label="ARROCES DEL MUNDO" v-model="pb.buffetArroces"></v-checkbox>
                <div class="ms-8">
                  <h4 class="info--text">Fideuá de chipirones en dos texturas</h4>
                  <h4 class="info--text">Risotto de arroz carnaroli con ceps y trompetillas</h4>
                  <h4 class="info--text">Encurtidos & Laterío "Barrecha"</h4>
                  <h4 class="info--text">Vermut casero macerado con naranja</h4>
                </div>
                <v-checkbox class="mt-0" hide-details="" label="ESPETOS" v-model="pb.buffetEspetos"></v-checkbox>
                <div class="ms-8">
                  <h4 class="info--text">Pulpo y panceta a la parrilla</h4>
                  <h4 class="info--text">Chipirones frescos con su picada</h4>
                </div>
                <v-checkbox class="mt-0" hide-details="" label="TAKE AWAY" v-model="pb.buffetTakeAway"></v-checkbox>
                <div class="ms-8">
                  <h4 class="info--text">Gigot de ternera - tupinambo - patatas paja</h4>
                  <h4 class="info--text">Gulas a la Bilbana y crackers</h4>
                </div>
                <v-checkbox class="mt-0" hide-details="" label="KM.0" v-model="pb.buffetKm0"></v-checkbox>
                <div class="ms-8">
                  <h4 class="info--text">Huevos estrellados</h4>
                  <h4 class="info--text">Migas de pastor</h4>
                </div>
                <v-checkbox class="mt-0" hide-details="" label="TACOS BAR" v-model="pb.buffetTacos"></v-checkbox>
                <div class="ms-8">
                  <h4 class="info--text">Pulled pork - barbacoa - jalapeños</h4>
                  <h4 class="info--text">Mini kebab de pollo desmechado</h4>
                </div>
                <v-checkbox class="mt-0" hide-details="" label="COCINA EN DIRECTO" v-model="pb.buffetCocina"></v-checkbox>
                <div class="ms-8">
                  <h4 class="info--text">Magret de pato - reducción PC - confitura cereza</h4>
                  <h4 class="info--text">Gamba de Palamós a la plancha</h4>
                </div>
              </v-col>
              <v-col cols="12" md="6">
                <h2 class="primary--text">ESPECTÁCULO (no incluido)</h2>
                <v-checkbox class="mt-0" hide-details="" label="PULPEIRO" v-model="pb.pulpeiro"></v-checkbox>
                <div class="ms-8">
                  <h4 class="info--text">Pulpo a la gallega - patata . alioli de pimentón</h4>
                </div>
                <v-checkbox class="mt-0" hide-details="" label="MARINERO" v-model="pb.marinero"></v-checkbox>
                <div class="ms-8">
                  <h4 class="info--text">Gambas a la sal - canaillas - mejillones al vapor - langostinos - berberechos</h4>
                </div>
                <v-checkbox class="mt-0" hide-details="" label="AHUMADOS" v-model="pb.ahumados"></v-checkbox>
                <div class="ms-8">
                  <h4 class="info--text">salmón noruego</h4>
                  <h4 class="info--text">Bacalao Islandia</h4>
                  <h4 class="info--text">Salsa tártara, confitura de pimiento asado, japosalsa</h4>
                </div>
                <v-checkbox class="mt-0" hide-details="" label="PURO SHOW - PURA VACA" v-model="pb.buffetJapones"></v-checkbox>
                <div class="ms-8">
                  <h4 class="info--text">Chuletón Vaca madurada 40 días Summatura</h4>
                  <h4 class="info--text">Entrecotte Vaca madurada 40 días Summatura</h4>
                  <h4 class="info--text">Patata asada, pimientos de Lodosa y selección de mostazas</h4>
                </div>
                <v-divider class="mb-3"></v-divider>
                <h2 class="primary--text">OTRAS DELICATESSEN</h2>
                <h4 class="primery--text">(ELEGIR 6)</h4>
                <v-checkbox class="mt-0" hide-details="" label="Capuccio de foie con polvo de trufa" v-model="pb.delCapuccino"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Air baguette de jamón ibérico de Guijuelo" v-model="pb.delAirBaguette"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Ssäm de pollo crispy con lima y chile" v-model="pb.delSsam"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Croquetas de pollo a la chilindrón" v-model="pb.delCroquetas"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Coca de roastbeef, germinados & tártara" v-model="pb.delCoca"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Empanadillas crujientes de queso Rio Vero y portobello" v-model="pb.delEmpanadillas"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Bikini de berenjena trufada" v-model="pb.delBikini"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Arroz meloso de pato, rosignol, reducción de PX" v-model="pb.delArroz"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Tataki de atún, vinagreta de soja y ensalada wakame" v-model="pb.delTataki"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Mini canelón de pularda y trufa" v-model="pb.delMini"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Borraja en tempura y miel" v-model="pb.delBorraja"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Bao de confit de pato salsa hoisin y cebolla roja" v-model="pb.delBao"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Huevo poché - sobrasada - trufa de verano" v-model="pb.delHuevo"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="MCFarré de calamares bravos" v-model="pb.delMcfarre"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Mini croissant de costilla ahumada" v-model="pb.delMiniCroissant"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Cracker de algas, salmón y stracciatella" v-model="pb.delCracker"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Crujiente de manitas de cerdo y tartar de gamba roja" v-model="pb.delCrujiente"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Zamburiñas gallegas gratinadas" v-model="pb.delZamb"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Tonaktzu de langostinos" v-model="pb.delTonaktzu"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Brioche de rabo de buey con cebolla frita" v-model="pb.delBrioche"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Tosta de mantequilla con anguila ahumada y foie" v-model="pb.delTosta"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Vieira con crema de calabaza" v-model="pb.delVieira"></v-checkbox>
                <v-divider class="my-2"></v-divider>
                <h2 class="primary--text">BAR</h2>
                <v-checkbox class="mt-0" hide-details="" label="Aguas, Refrescos, Cervezas, Vermut" v-model="pb.bar"></v-checkbox>
                <v-divider class="my-2"></v-divider>
                <h2 class="primary--text">BODEGA</h2>
                <v-checkbox class="mt-0" hide-details="" label="Vinos DO Somontano (blanco, rosado y tinto)" v-model="pb.bar"></v-checkbox>
                <v-divider class="my-2"></v-divider>
                <h2 class="primary--text">CERVECEANDO (no incluido)</h2>
                <v-checkbox class="mt-0" hide-details="" label="Selección de cervezas nacionales o intenacionales" v-model="pb.bar"></v-checkbox>
                <v-divider class="my-2"></v-divider>
                <h2 class="primary--text">OBSERVACIONES APERITIVO:</h2>
                <v-textarea filled dense hide-details="" v-model="pb.obsAptvo"></v-textarea>
              </v-col>
            </v-row>
          </template>
          <template v-if="page == 4">
            <h1 class="primary--text mb-5">MENÚ</h1>
            <v-row>
              <v-col cols="12">
                <h2 class="primary--text">PARA EMPEZAR (no incluido)</h2>
                <v-checkbox class="mt-0" hide-details="" label="Salmorejo de tomate rosa y cerezas, concassé de piña y langostino" v-model="pb.menuSalmorejo"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Sopita fría de carabineros, vieiras a la plancha, nube de jamón ibérico" v-model="pb.menuSopita"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Tartar de atún con guacamole y sopita de tomate km0" v-model="pb.menuTartar"></v-checkbox>
                <v-divider class="my-2"></v-divider>
                <h2 class="primary--text">PRIMER PLATO (elegir tres)</h2>
                <v-checkbox class="mt-0" hide-details="" label="Ensalada de langostinos, escabeche oriental de vieiras y vinagreta de maracuyá" v-model="pb.menuEnsaladaLangostinos"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Cannelloni de pasta fresca con presa ibérica, trompeta negra, rosignol y demiglacé" v-model="pb.menuCannelloni"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Ravioli de txangurro y carabineros con emulsión de gamba roja" v-model="pb.menuRavioli"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Canelón de rape y langostinos con royal de cigalas" v-model="pb.menuCanelon"></v-checkbox>
                <v-checkbox hide-details="" label="Pulpo braseado - cremoso de patata - alioli de pimentón - guacamole" v-model="pb.menuPulpo"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Merluza con marinera de sepia y pulpo y gratén de boletus" v-model="pb.menuMerluza"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Bacalao 70º con tradicional salsa verde, langostino y crujiente de gambas y almendras" v-model="pb.menuBacalao"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Lubina con ragout de ajos, ajetes, shitakes, trigueros y vieiras con suave alioli" v-model="pb.menuLubina"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Rodaballo con chipirones en su tinta y muselina de gamba roja" v-model="pb.menuRodaballo"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Ensalada de medio Bogavante gallego y su vinagreta de coral" v-model="pb.menuEnsaladaMedioBogavante"></v-checkbox>
                <v-checkbox hide-details="" label="Ensalada de bogavante gallego y tartar de cítricos" v-model="pb.menuEnsaladaBogavante"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Bogavante gallego asado a la parrilla" v-model="pb.menuBogavante"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Langosta imperial en dos texturas (media)" v-model="pb.menuLangosta"></v-checkbox>
                <v-divider class="my-2"></v-divider>
                <h2 class="primary--text">SORBETES (no incluido)</h2>
                <h4 class="info--text">Orange Fantasy / Green Love / Tropic Passion / Pink&Prite / Fresas & Möet / Gewürztraminer</h4>
                <v-divider class="my-2"></v-divider>
                <h2 class="primary--text">CARNES (elegir tres)</h2>
                <v-checkbox class="mt-0" hide-details="" label="Costila ibérica de Jabugo ahumada y rustida con parmentier a la francesa" v-model="pb.menuCostilla"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Jarrete de lechazo al homo, con romero y patata rota" v-model="pb.menuJarrete"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Paletila de ternasco asado al modo tradicional con patatas panadera" v-model="pb.menuPaletillaTernasco"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Ternasco glaseado a baja temperatura - patata trufada - boniato" v-model="pb.menuTernasco"></v-checkbox>
                <v-checkbox hide-details="" label="Solomillo de ternera - reducción de cabernet Sauvignon - tupinambo - manzana" v-model="pb.menuSolomillo"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Solomillo de ternera con escalopa de foile, cremoso de patata y oporto" v-model="pb.menuSolomilloFoie"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Lechazo de Castita, pimientos de cristal y cebollitas glaseadas" v-model="pb.menuLechazo"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Paletilla de cabrito con patatas panadera, romero, ajo y perejil" v-model="pb.menuPaletillaCabrito"></v-checkbox>
                <v-divider class="my-2"></v-divider>
                <h2 class="primary--text">SWEET IS LOVE (se degustarán todos)</h2>
                <h4 class="info--text">Lemon Pie & merengue</h4>
                <h4 class="info--text">Brownie de chocolate 70% con crema de café</h4>
                <h4 class="info--text">Milhojas caramelizado y emulsión de crema pastelera</h4>
                <h4 class="info--text">Bombón helado de chocolate y sus satélites</h4>
                <h4 class="info--text">Versión del tiramisú</h4>
                <h4 class="info--text">Sacher crujiente con crema de naranja sanguina</h4>
                <h4 class="info--text mt-2">Misterio de texturas de chocolate</h4>
                <v-radio-group
                hide-details=""
                v-model="pb.presentacionTarta"
                column
                >
                  <div class="d-flex flex-row align-center">
                    <h4 style="font-weight: bold;">PRESENTACIÓN DE TARTA:</h4>
                    <v-radio class="my-0 mx-3" hide-details="" :value="true" label="SI"></v-radio>
                    <v-radio class="my-0 ms-3" hide-details="" :value="false" label="NO"></v-radio>
                  </div>
                </v-radio-group>
              </v-col>
              <v-col cols="12">
                <h2 class="primary--text">OBSERVACIONES MENÚ</h2>
                <div class="ms-5">
                  <v-textarea filled dense hide-details="" v-model="pb.obsMenu"></v-textarea>
                </div>
              </v-col>
            </v-row>
          </template>
          <template v-if="page == 5">
            <h1 class="primary--text mb-5">BODEGA</h1>
            <v-row>
              <v-col cols="12" md="4">
                <h2 class="primary--text">BLANCOS</h2>
                <v-checkbox class="mt-0" hide-details="" label="Viñas del Vero Macabeo-Chardonnay" v-model="pb.blancoVdvMacabeo"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Laus Chardonnay" v-model="pb.blancoLaus"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Pirineos 3404" v-model="pb.blancoPirineo"></v-checkbox>
                <v-checkbox hide-details="" label="Laura Lalanne" v-model="pb.blancoLaura"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Enate 234 Chardonnay" v-model="pb.blacoEnate"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Viñas del Vero Gewürztraminer" v-model="pb.blancoVdvGewurz"></v-checkbox>
              </v-col>
              <v-col cols="12" md="4">
                <h2 class="primary--text">TINTOS</h2>
                <v-checkbox class="mt-0" hide-details="" label="Viñas del Vero Crianza" v-model="pb.tintoVdv"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Laus Crianza" v-model="pb.tintoLaus"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="De Beroz Crianza" v-model="pb.tintoBeroz"></v-checkbox>
                <v-checkbox hide-details="" label="Lalanne Crianza" v-model="pb.tintoLalanne"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Enate Crianza" v-model="pb.tintoEnate"></v-checkbox>
              </v-col>
              <v-col cols="12" md="4">
                <h2 class="primary--text">CAVAS</h2>
                <v-checkbox class="mt-0" hide-details="" label="Cava Reyes de Aragón Brut" v-model="pb.cavaReyes"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Freixenet Vintage Reserva Brut" v-model="pb.cavaFreixenet"></v-checkbox>
                <v-checkbox hide-details="" label="Lucrecia Lalanne Brut" v-model="pb.cavaLucrecia"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Agustí Torelló&Mata Brut Nature" v-model="pb.cavaAgusti"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Cava Juvé&Camps reserva de Familia" v-model="pb.cavaJuve"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Otra bodega o Cava (detallar)" v-model="pb.otroCava"></v-checkbox>
                <v-textarea v-if="pb.otroCava" filled dense label="Otra Bodega" hide-details="" v-model="pb.otroCavaDetallado"></v-textarea>
              </v-col>
            </v-row>
            <h1 class="primary--text mb-5">MENÚ INFANTIL</h1>
            <v-row>
              <v-col cols="12">
                <v-checkbox class="mt-0" hide-details="" label="Macarrones con tomate casero" v-model="pb.infMacarrones"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Macarrones blancos" v-model="pb.infMacarronesBlancos"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Añadir canelones de carne gratinados" v-model="pb.infCanelones"></v-checkbox>
                <v-checkbox hide-details="" label="Finguers de pollo con patatas fritas" v-model="pb.infFingers"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Longaniza a la brasa con patatas fritas" v-model="pb.infLonganiza"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Hamburguesa al plato con patatas" v-model="pb.infHamburguesa"></v-checkbox>
                <v-radio-group
                hide-details=""
                v-model="pb.guarderia"
                column
                >
                  <div class="d-flex flex-row align-center">
                    <h4 style="font-weight: bold;">GUARDERÍA:</h4>
                    <v-radio class="my-0 mx-3" hide-details="" :value="true" label="SI"></v-radio>
                    <v-radio class="my-0 ms-3" hide-details="" :value="false" label="NO"></v-radio>
                  </div>
                </v-radio-group>
                <v-textarea filled dense label="Intolerancias y observaciones" hide-details="" v-model="pb.infObs"></v-textarea>
              </v-col>
            </v-row>
            <h1 class="primary--text my-5">MENÚS ESPECIALES (detallar)</h1>
            <v-row>
              <v-col cols="12">
                <v-textarea filled dense hide-details="" v-model="pb.menuEspecial"></v-textarea>
              </v-col>
            </v-row>
            <h1 class="primary--text my-5">OTRAS OBSERVACIONES</h1>
            <v-row>
              <v-col cols="12">
                <v-textarea filled dense hide-details="" v-model="pb.obsMenuInf"></v-textarea>
              </v-col>
            </v-row>
          </template>
          <template v-if="page == 6">
            <h1 class="primary--text mb-5">BODA A LA CARTA</h1>
            <v-row>
              <v-col cols="12" md="6">
                <h2 class="primary--text">FIN DE FIESTA</h2>
                <v-checkbox class="mt-0" hide-details="" label="Licores en mesa" v-model="pb.ffLicores"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Barra llibre" v-model="pb.ffBarra"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Otros licores - detallar:" v-model="pb.ffOtros"></v-checkbox>
                <v-textarea v-if="pb.ffOtros" filled dense label="" hide-details="" v-model="pb.otroFf"></v-textarea>
                <v-divider class="my-2"></v-divider>
                <h2 class="primary--text">RECENA</h2>
                <v-checkbox class="mt-0" hide-details="" label="Tradicional" v-model="pb.recenaTradicional"></v-checkbox>
                <h4 class="info--text">Panini de bacon y queso</h4>
                <h4 class="info--text">Coca de longaniza de Graus</h4>
                <h4 class="info--text">Tortilla de patata</h4>
                <h4 class="info--text">Mini donuts & chocolate caliente</h4>
                <h2>Especial</h2>
                <v-checkbox class="mt-0" hide-details="" label="Buffet de variedad de tortillas" v-model="pb.recenaEspecialBuffetTortilla"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Buffet de sándwiches (Mixto, vegetal y Club)" v-model="pb.recenaEspecialBuffetSand"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Hotdogs con mostaza y kétchup" v-model="pb.recenaEspecialHotdogs"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Mini burguer" v-model="pb.recenaEspecialMiniBurguer"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Pizzas" v-model="pb.recenaEspecialPizzas"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Barbecue (longaniza, chorizo y panceta)" v-model="pb.recenaEspecialBbq"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Brownie y Tarta de zanahoria" v-model="pb.recenaEspecialBrownie"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Palomitas y Candy Bar" v-model="pb.recenaEspecialPalomitas"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Jamón al corte" v-model="pb.recenaEspecialJamón"></v-checkbox>
                <v-divider class="my-2"></v-divider>
                <h2 class="primary--text">CARTELERÍA</h2>
                <h4 class="primary--text">(Tarjeta Aperitivo - Tarjeta Menú - Mesero - Seating)</h4>
                <div class="d-flex flex-row">
                  <v-checkbox class="mt-0" hide-details="" label="Modelo MasFarré (detallar modelo):" v-model="pb.carteleriaMf"></v-checkbox>
                  <v-text-field class="mb-4 mt-3" v-if="pb.carteleriaMf" filled dense label="" hide-details="" v-model="pb.otroFf"></v-text-field>
                </div>
                <v-checkbox class="mt-0" hide-details="" label="La pareja tiene logo / iniciales / etc..." v-model="pb.carteleriaPareja"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="La pareja aporta seating - detallar" v-model="pb.carteleriaSeating"></v-checkbox>
                <v-textarea v-if="pb.carteleriaSeating" filled dense label="" hide-details="" v-model="pb.otroFf"></v-textarea>
                <v-checkbox class="mt-0" hide-details="" label="Se encarga la pareja de toda la carteleria" v-model="pb.carteleriaParejaTodo"></v-checkbox>
                <v-divider class="my-2"></v-divider>
                <h2 class="primary--text">DECORACIÓN ESPACIOS</h2>
                <v-checkbox class="mt-0" hide-details="" label="Decoración floral (mesas) - detallar floristería:" v-model="pb.decoracionFloral"></v-checkbox>
                <v-textarea v-if="pb.decoracionFloral" filled dense label="" hide-details="" v-model="pb.decoracionDetallar"></v-textarea>
                <v-divider class="my-2"></v-divider>
                <h2 class="primary--text">DISTRIBUCIÓN INVITADOS</h2>
                <v-textarea filled dense label="Indicar número de personas en presidencia:" hide-details="" v-model="pb.otroFf"></v-textarea>
                <v-textarea filled dense label="Otras observaciones de distribución:" hide-details="" v-model="pb.decoracionDetallar"></v-textarea>
                <v-divider class="my-2"></v-divider>
                <h2 class="primary--text">MÚSICA</h2>
                <h4 style="font-weight: bold;">ENLACE CIVIL</h4>
                <v-radio-group
                class="mt-0"
                hide-details=""
                v-model="pb.musicaEnlace"
                column
                >
                  <div class="d-flex flex-row align-center">
                    <v-radio class="my-0 mx-3" hide-details="" :value="true" label="Se encarga MasFarré"></v-radio>
                    <v-radio class="my-0 ms-3" hide-details="" :value="false" label="Se encarga la pareja"></v-radio>
                  </div>
                </v-radio-group>
                <h4 style="font-weight: bold;">APERITIVO</h4>
                <v-radio-group
                class="mt-0"
                hide-details=""
                v-model="pb.musicaAperitivo"
                column
                >
                  <div class="d-flex flex-row align-center">
                    <v-radio class="my-0 mx-3" hide-details="" :value="true" label="Se encarga MasFarré"></v-radio>
                    <v-radio class="my-0 ms-3" hide-details="" :value="false" label="Se encarga la pareja"></v-radio>
                  </div>
                </v-radio-group>
                <h4 style="font-weight: bold;">SALÓN</h4>
                <v-radio-group
                class="mt-0"
                hide-details=""
                v-model="pb.musicaSalon"
                column
                >
                  <div class="d-flex flex-row align-center">
                    <v-radio class="my-0 mx-3" hide-details="" :value="true" label="Se encarga MasFarré"></v-radio>
                    <v-radio class="my-0 ms-3" hide-details="" :value="false" label="Se encarga la pareja"></v-radio>
                  </div>
                </v-radio-group>
                <h4 style="font-weight: bold;">DISCO MOVIL</h4>
                <v-radio-group
                class="mt-0"
                hide-details=""
                v-model="pb.musicaDisco"
                column
                >
                  <div class="d-flex flex-row align-center">
                    <v-radio class="my-0 mx-3" hide-details="" :value="true" label="Se encarga MasFarré"></v-radio>
                    <v-radio class="my-0 ms-3" hide-details="" :value="false" label="Se encarga la pareja"></v-radio>
                  </div>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6">
                <h2 class="primary--text">DETALLES INVITADOS (cuando se entregará)</h2>
                <h4 style="font-weight: bold;">DETALLE</h4>
                <v-radio-group
                class="mt-0"
                hide-details=""
                v-model="pb.detalle"
                column
                >
                  <div class="d-flex flex-row align-center">
                    <v-radio class="my-0 mx-3" hide-details="" :value="true" label="Si - ¿Qué es?"></v-radio>
                    <v-text-field class="mb-4 mt-3" v-if="pb.detalle" filled dense label="" hide-details="" v-model="pb.detalleQueEs"></v-text-field>
                    <v-radio class="my-0 ms-3" hide-details="" :value="false" label="NO"></v-radio>
                  </div>
                </v-radio-group>
                <h4 style="font-weight: bold;">CUÁNDO SE ENTREGARÁ</h4>
                <v-radio-group
                class="mt-0"
                hide-details=""
                v-model="pb.cuandoDetalle"
                column
                >
                  <div class="d-flex flex-row align-center">
                    <v-radio class="my-0 mx-3" hide-details="" :value="true" label="Puesto en mesa"></v-radio>
                    <v-radio class="my-0 ms-3" hide-details="" :value="false" label="Durante los cafés"></v-radio>
                  </div>
                </v-radio-group>
                <h4 style="font-weight: bold;">QUIÉN LO ENTREGARÁ</h4>
                <v-radio-group
                class="mt-0"
                hide-details=""
                v-model="pb.quienDetalle"
                column
                >
                  <div class="d-flex flex-row align-center">
                    <v-radio class="my-0 mx-3" hide-details="" :value="true" label="Pareja"></v-radio>
                    <v-radio class="my-0 ms-3" hide-details="" :value="false" label="Otros"></v-radio>
                  </div>
                </v-radio-group>
                <v-divider class="my-2"></v-divider>
                <h2 class="primary--text">TÉCNICOS</h2>
                <v-text-field class="mb-4 mt-3" dense label="Empresas contratadas" hide-details="" v-model="pb.empresasContratadas"></v-text-field>
                <v-text-field class="mb-4 mt-3" dense label="Total técnicos que comen" hide-details="" v-model="pb.tecnicosComen"></v-text-field>
                <h4 style="font-weight: bold;">REPORTAJE CON LOS INVITADOS:</h4>
                <v-radio-group
                class="mt-0"
                hide-details=""
                v-model="pb.reportaje"
                column
                >
                  <div class="d-flex flex-row align-center">
                    <v-radio class="my-0 mx-3" hide-details="" :value="1" label="Después del enlace"></v-radio>
                    <v-radio class="my-0 ms-3" hide-details="" :value="2" label="Después del aperitivo"></v-radio>
                    <v-radio class="my-0 ms-3" hide-details="" :value="3" label="En el salón"></v-radio>
                    <v-radio class="my-0 ms-3" hide-details="" :value="4" label="Por definir"></v-radio>
                    <v-radio class="my-0 ms-3" hide-details="" :value="5" label="No habrá"></v-radio>
                  </div>
                </v-radio-group>
                <v-divider class="my-2"></v-divider>
                <h2 class="primary--text">OTROS DETALLES</h2>
                <h4 class="primary--text">(contratados por la pareja)</h4>
                <v-checkbox class="mt-0" hide-details="" label="Proyección" v-model="pb.parejaProyeccion"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Photocall" v-model="pb.parejaPhotocall"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Fotomatón" v-model="pb.parejaFotomaton"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Otros" v-model="pb.parejaOtros"></v-checkbox>
                <v-text-field class="mb-4 mt-3" v-if="pb.parejaOtros" dense label="Detallar otros" hide-details="" v-model="pb.parejaOtrosDetallado"></v-text-field>
                <v-checkbox class="mt-0" hide-details="" label="Parking" v-model="pb.parejaParking"></v-checkbox>
                <div class="ms-8" v-if="pb.parejaParking">
                  <v-text-field class="mb-4 mt-3" dense label="Necesidades" hide-details="" v-model="pb.parejaParkingNecesidades"></v-text-field>
                  <v-text-field class="mb-4 mt-3" dense label="Ubicación" hide-details="" v-model="pb.parejaParkingUbicacion"></v-text-field>
                </div>
                <v-radio-group
                hide-details=""
                v-model="pb.parejaBus"
                column
                >
                  <div class="d-flex flex-row align-center">
                    <h4 style="font-weight: bold;">Bus:</h4>
                    <v-radio class="my-0 mx-3" hide-details="" :value="true" label="SI"></v-radio>
                    <v-text-field class="mb-4 mt-3" v-if="pb.parejaBus" filled dense label="Horario" hide-details="" v-model="pb.parejaBusHorario"></v-text-field>
                    <v-radio class="my-0 ms-3" hide-details="" :value="false" label="NO"></v-radio>
                  </div>
                </v-radio-group>
                <v-divider class="my-2"></v-divider>
                <h2 class="primary--text">OTROS DETALLES</h2>
                <v-checkbox class="mt-0" hide-details="" label="Necesidad de suite nupcial" v-model="pb.suite"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Disfrutarán otro día de la suite" v-model="pb.otroDiaSuite"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="No necesitan habitaciones" v-model="pb.sinHabitaciones"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Necesitan habitaciones" v-model="pb.habitaciones"></v-checkbox>
                <v-text-field class="mb-4 mt-3" v-if="pb.habitaciones" filled dense label="Nº habitaciones" hide-details="" v-model="pb.nHabitaciones"></v-text-field>
                <h4 style="font-weight: bold;">(reservas@gmasfarre.com)</h4>
                <v-radio-group
                hide-details=""
                v-model="pb.bloqueo"
                column
                >
                  <v-radio class="my-0 mx-3" hide-details="" :value="true" label="Existe bloqueo previo"></v-radio>
                  <v-radio class="my-0 ms-3" hide-details="" :value="false" label="No hay bloqueo"></v-radio>
                </v-radio-group>
                <v-divider class="my-2"></v-divider>
                <h2 class="primary--text">OBSERVACIONES Y DETALLES EN GENERAL</h2>
                <v-textarea filled dense label="" hide-details="" v-model="pb.decoracionBodaALaCarta"></v-textarea>
              </v-col>
            </v-row>
          </template>
        </v-form>
      </v-card-text>
      <v-card-actions :class="$vuetify.theme.dark ? 'sticky-actions-dark' : 'sticky-actions-light'">
        <v-btn color="error" @click="cancel">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-btn color="primary" @click="agree">
          <v-icon>mdi-floppy</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <div class="d-flex justify-center align-center">
          <v-btn icon small fab @click="prev" v-if="page < 2">Volver</v-btn>
          {{ page }}
          <v-btn color="primary" icon small fab @click="next" v-if="page >= maxPages-1">Siguiente</v-btn>
          <v-btn color="success" icon small fab @click="send" v-if="page == maxPages"><v-icon>Finalizar</v-icon></v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    publicUuidv4: null,
    privateUuidv4: null,
    dialog: false,
    datos: {},
    resolve: null,
    valid: true,
    reject: null,
    page: 1,
    options: {
      width: 1000,
      zIndex: 200
    },
    superFamilias:[
      { id : 0, nombre:"APERITIVO" },
      { id : 1, nombre:"MENÚ" },
      { id : 2, nombre:"BODEGA" },
      { id : 3, nombre:"RECENA" },
    ],
    cartas:[
      { id : 1, nombre:"CLÁSICA" },
      { id : 2, nombre:"CÓCTEL" },
    ],
    familias:[
      {idFamilia: 1, nombreFamilia: "RECEPCIÓN" },
      {idFamilia: 2, nombreFamilia: "BUFFETS DE BIENVENIDA"},
      {idFamilia: 3, nombreFamilia: "TEMÁTICOS" },
      {idFamilia: 4, nombreFamilia: "ESPECTÁCULO (no incluido)" },
      {idFamilia: 5, nombreFamilia: "OTRAS DELICATESSEN" },
      // {idFamilia: 6, nombreFamilia: "BODEGA" },
      {idFamilia: 7, nombreFamilia: "UN RESPIRO" },
      {idFamilia: 8, nombreFamilia: "PARA ACABAR" },
      {idFamilia: 11, nombreFamilia: "PARA EMPEZAR"},
      // {idFamilia: 10, nombreFamilia: "BAR" },
      {idFamilia: 12, nombreFamilia: "PRIMER PLATO" },
      {idFamilia: 13, nombreFamilia: "SORBETES"},
      {idFamilia: 14, nombreFamilia: "CARNES" },
      {idFamilia: 15, nombreFamilia: "POSTRES"},
      {idFamilia: 9, nombreFamilia: "MOMENTO DULCE" },
      // {idFamilia: 16, nombreFamilia: "VINO BLANCO" },
      // {idFamilia: 17, nombreFamilia: "VINO TINTO" },
      // {idFamilia: 18, nombreFamilia: "CAVA" },
      {idFamilia: 19, nombreFamilia: "RECENA" },
      // {idFamilia: 20, nombreFamilia: "FIN DE FIESTA"},
      // {idFamilia: 21, nombreFamilia: "CARTELERÍA" },
      // {idFamilia: 22, nombreFamilia: "DECORACIÓN ESPACIOS" },
    ],
    productos:[
      { espacios :[10], cartas: [1,2], idFamilia: 1, superFamilia:0, key:"cavaYLimonada", nombreProducto: "CAVA y LIMONADA"},
      { espacios :[10], cartas: [1,2], idFamilia: 1, superFamilia:0, key:"recepcionOtros", nombreProducto: "OTROS", writable: true},
      { espacios :[10], cartas: [1,2], idFamilia: 2, superFamilia:0, key:"jamoncito", nombreProducto: "JAMONCITO"},
      { espacios :[10], cartas: [1,2], idFamilia: 2, superFamilia:0, key:"laCharcuteria", nombreProducto: "LA CHARCUTERIA"},
      { espacios :[10], cartas: [1,2], idFamilia: 2, superFamilia:0, key:"laQueseria", nombreProducto: "LA QUESERÍA"},
      { espacios :[10], cartas: [1,2], idFamilia: 2, superFamilia:0, key:"japones", nombreProducto: "JAPONÉS"},
      { espacios :[10], cartas: [1,2], idFamilia: 2, superFamilia:0, key:"pato", nombreProducto: "PATO"},
      { espacios :[10], cartas: [1,2], idFamilia: 2, superFamilia:0, key:"vermuteria", nombreProducto: "VERMUTERÍA"},
      { espacios :[10], cartas: [1,2], idFamilia: 2, superFamilia:0, key:"otrosBuffets", nombreProducto: "OTROS", writable: true},
      { espacios :[10], cartas: [1,2], idFamilia: 3, superFamilia:0, key:"arroces", nombreProducto: "ARROCES"},
      { espacios :[10], cartas: [1,2], idFamilia: 3, superFamilia:0, key:"espetos", nombreProducto: "ESPETOS"},
      { espacios :[10], cartas: [1,2], idFamilia: 3, superFamilia:0, key:"takeAway", nombreProducto: "TAKE AWAY"},
      { espacios :[10], cartas: [1,2], idFamilia: 3, superFamilia:0, key:"km0", nombreProducto: "KM0"},
      { espacios :[10], cartas: [1,2], idFamilia: 3, superFamilia:0, key:"tacosBar", nombreProducto: "TACOS BAR"},
      { espacios :[10], cartas: [1,2], idFamilia: 3, superFamilia:0, key:"cocinaEnDirecto", nombreProducto: "COCINA DIRECTO"},
      { espacios :[10], cartas: [1,2], idFamilia: 3, superFamilia:0, key:"otrosTematicos", nombreProducto: "OTROS", writable: true},
      { espacios :[10], cartas: [1,2], idFamilia: 4, superFamilia:0, key:"pulpeiro",nombreProducto: "PULPEIRO"},
      { espacios :[10], cartas: [1,2], idFamilia: 4, superFamilia:0, key:"marinero",nombreProducto:"MARINERO"},
      { espacios :[10], cartas: [1,2], idFamilia: 4, superFamilia:0, key:"ahumados",nombreProducto:"AHUMADOS"},
      { espacios :[10], cartas: [1,2], idFamilia: 4, superFamilia:0, key:"puroShow",nombreProducto:"PURO SHOW - PURA VACA"},
      { espacios :[10], cartas: [1,2], idFamilia: 4, superFamilia:0, key:"otrosEspectaculo",nombreProducto:"OTROS", writable:true},
      { espacios :[10], cartas: [1,2], idFamilia: 5, superFamilia:0, key:"capuccino",nombreProducto: "CAPUCCINO"},
      { espacios :[10], cartas: [1,2], idFamilia: 5, superFamilia:0, key:"air",nombreProducto: "AIR"},
      { espacios :[10], cartas: [1,2], idFamilia: 5, superFamilia:0, key:"ssam",nombreProducto: "SSÄM"},
      { espacios :[10], cartas: [1,2], idFamilia: 5, superFamilia:0, key:"croquetas",nombreProducto: "CROQUETAS"},
      { espacios :[10], cartas: [1,2], idFamilia: 5, superFamilia:0, key:"coca",nombreProducto: "COCA"},
      { espacios :[10], cartas: [1,2], idFamilia: 5, superFamilia:0, key:"empanadillas",nombreProducto: "EMPANADILLAS"},
      { espacios :[10], cartas: [1,2], idFamilia: 5, superFamilia:0, key:"bikini",nombreProducto: "BIKINI"},
      { espacios :[10], cartas: [1,2], idFamilia: 5, superFamilia:0, key:"arroz",nombreProducto: "ARROZ"},
      { espacios :[10], cartas: [1,2], idFamilia: 5, superFamilia:0, key:"tataki",nombreProducto: "TATAKI"},
      { espacios :[10], cartas: [1,2], idFamilia: 5, superFamilia:0, key:"miniCanelon",nombreProducto: "MINI CANELÓN"},
      { espacios :[10], cartas: [1,2], idFamilia: 5, superFamilia:0, key:"borraja",nombreProducto: "BORRAJA"},
      { espacios :[10], cartas: [1,2], idFamilia: 5, superFamilia:0, key:"bao",nombreProducto: "BAO"},
      { espacios :[10], cartas: [1,2], idFamilia: 5, superFamilia:0, key:"huevoPache",nombreProducto: "HUEVO PACHÉ"},
      { espacios :[10], cartas: [1,2], idFamilia: 5, superFamilia:0, key:"mcfarre",nombreProducto: "MCFARRÉ"},
      { espacios :[10], cartas: [1,2], idFamilia: 5, superFamilia:0, key:"mini",nombreProducto: "MINI CROISSANT"},
      { espacios :[10], cartas: [1,2], idFamilia: 5, superFamilia:0, key:"cracker",nombreProducto: "CRACKER"},
      { espacios :[10], cartas: [1,2], idFamilia: 5, superFamilia:0, key:"crujiente",nombreProducto: "CRUJIENTE"},
      { espacios :[10], cartas: [1,2], idFamilia: 5, superFamilia:0, key:"zamburinas",nombreProducto: "ZAMBURIÑAS"},
      { espacios :[10], cartas: [1,2], idFamilia: 5, superFamilia:0, key:"tonaktzu",nombreProducto: "TONAKTZU"},
      { espacios :[10], cartas: [1,2], idFamilia: 5, superFamilia:0, key:"brioche",nombreProducto: "BRIOCHE"},
      { espacios :[10], cartas: [1,2], idFamilia: 5, superFamilia:0, key:"tostaDeMantequilla",nombreProducto: "TOSTA MTQLLA"},
      { espacios :[10], cartas: [1,2], idFamilia: 5, superFamilia:0, key:"vieira",nombreProducto: "VIEIRA"},
      { espacios :[10], cartas: [1,2], idFamilia: 5, superFamilia:0, key:"milHojas",nombreProducto: "MIL HOJAS DE FOIE"},
      { espacios :[10], cartas: [1,2], idFamilia: 5, superFamilia:0, key:"otrosDelicatessen",nombreProducto: "OTROS", writable:true},
      // { espacios :[10], cartas: [1], idFamilia: 6, superFamilia:0, key:"vinosBlancoTinto", nombreProducto: "VINOS BLANCO Y TINTO"},
      { espacios :[10], cartas: [2], idFamilia: 7, superFamilia:0, key:"sorbeteGewurztaminer", nombreProducto: "SBT GEWÜRZTAMINER"},
      { espacios :[10], cartas: [2], idFamilia: 7, superFamilia:0, key:"otrosRespiro", nombreProducto: "OTROS", writable:true},
      { espacios :[10], cartas: [2], idFamilia: 8, superFamilia:0, key:"entrecotteDeTernera", nombreProducto: "ENTRECOTTE TERNERA"},
      { espacios :[10], cartas: [2], idFamilia: 8, superFamilia:0, key:"otrosParaAcabar", nombreProducto: "OTROS", writable:true},
      // { espacios :[10], cartas: [1,2], idFamilia: 10, superFamilia:0, key:"aguasRefrescosCervezasVermut",nombreProducto: "AGUAS, REFRESCOS, CERVEZAS, VERMUT"},
      { espacios :[10], cartas: [1], idFamilia: 11, superFamilia:1, key:"salmorejo", nombreProducto: "SALMOREJO"},
      { espacios :[10], cartas: [1], idFamilia: 11, superFamilia:1, key:"sopitaFria",nombreProducto: "SOPITA FRÍA"},
      { espacios :[10], cartas: [1], idFamilia: 11, superFamilia:1, key:"tartarDeAtun",nombreProducto: "TARTAR ATÚN"},
      { espacios :[10], cartas: [1], idFamilia: 11, superFamilia:1, key:"otrosMenu",nombreProducto: "OTROS",writable:true},
      { espacios :[10], cartas: [1], idFamilia: 12, superFamilia:1, key:"ensalada", nombreProducto: "ENSALADA"},
      { espacios :[10], cartas: [1], idFamilia: 12, superFamilia:1, key:"canneloni", nombreProducto: "CANNELONI"},
      { espacios :[10], cartas: [1], idFamilia: 12, superFamilia:1, key:"ravioli", nombreProducto: "RAVIOLLI"},
      { espacios :[10], cartas: [1], idFamilia: 12, superFamilia:1, key:"canelonDeRape", nombreProducto: "CANELÓN RAPE"},
      { espacios :[10], cartas: [1], idFamilia: 12, superFamilia:1, key:"pulpoBraseado", nombreProducto: "PULPO BRASEADO"},
      { espacios :[10], cartas: [1], idFamilia: 12, superFamilia:1, key:"merluza", nombreProducto: "MERLUZA"},
      { espacios :[10], cartas: [1], idFamilia: 12, superFamilia:1, key:"bacalao", nombreProducto: "BACALAO"},
      { espacios :[10], cartas: [1], idFamilia: 12, superFamilia:1, key:"lubina", nombreProducto: "LUBINA"},
      { espacios :[10], cartas: [1], idFamilia: 12, superFamilia:1, key:"rodaballo", nombreProducto: "RODABALLO"},
      { espacios :[10], cartas: [1], idFamilia: 12, superFamilia:1, key:"ensaladaMedioBogavante", nombreProducto: "ENS. 1/2 BOGAV."},
      { espacios :[10], cartas: [1], idFamilia: 12, superFamilia:1, key:"ensaladaBogavante", nombreProducto: "ENS. BOGAV."},
      { espacios :[10], cartas: [1], idFamilia: 12, superFamilia:1, key:"bogavante", nombreProducto: "BOGAVANTE"},
      { espacios :[10], cartas: [1], idFamilia: 12, superFamilia:1, key:"langostaImperial", nombreProducto: "LANGOSTA IMPERIAL"},
      { espacios :[10], cartas: [1], idFamilia: 12, superFamilia:1, key:"otrosPrimero", nombreProducto: "OTROS", writable: true},
      { espacios :[10], cartas: [1], idFamilia: 13, superFamilia:1, key:"orangeFantasy", nombreProducto: "ORANGE FANTASY"},
      { espacios :[10], cartas: [1], idFamilia: 13, superFamilia:1, key:"greenLove", nombreProducto: "GREEN LOVE"},
      { espacios :[10], cartas: [1], idFamilia: 13, superFamilia:1, key:"tropicPassion", nombreProducto: "TROPIC PASSION"},
      { espacios :[10], cartas: [1], idFamilia: 13, superFamilia:1, key:"pinkPrite", nombreProducto: "PINK & PRITE"},
      { espacios :[10], cartas: [1], idFamilia: 13, superFamilia:1, key:"fresasMoet", nombreProducto: "FRESAS & MÖET"},
      { espacios :[10], cartas: [1], idFamilia: 13, superFamilia:1, key:"gewurztraminer", nombreProducto: "GEWURZTRAMINER"},
      { espacios :[10], cartas: [1], idFamilia: 13, superFamilia:1, key:"otrosSorbetes", nombreProducto: "OTROS", writable:true},
      { espacios :[10], cartas: [1], idFamilia: 14, superFamilia:1, key:"costillaIberica",nombreProducto: "COSTILLA IBÉRICA"},
      { espacios :[10], cartas: [1], idFamilia: 14, superFamilia:1, key:"jarreteDeLechazo",nombreProducto:"JARRETE LECHAZO"},
      { espacios :[10], cartas: [1], idFamilia: 14, superFamilia:1, key:"paletillaTernasco", nombreProducto: "PALETILLA TERNASCO"},
      { espacios :[10], cartas: [1], idFamilia: 14, superFamilia:1, key:"ternascoGlaseado", nombreProducto: "TERNASCO GLASEADO"},
      { espacios :[10], cartas: [1], idFamilia: 14, superFamilia:1, key:"solomilloTerneraReduccion", nombreProducto: "SOLOM. TRNRA"},
      { espacios :[10], cartas: [1], idFamilia: 14, superFamilia:1, key:"solomilloTerneraEscalopa", nombreProducto: "SOLOM. TRNRA + FOIE"},
      { espacios :[10], cartas: [1], idFamilia: 14, superFamilia:1, key:"lechazoDeCastilla", nombreProducto: "LECHAZO CASTILLA"},
      { espacios :[10], cartas: [1], idFamilia: 14, superFamilia:1, key:"paletillaDeCabrito", nombreProducto: "PALETILLA CABRITO"},
      { espacios :[10], cartas: [1], idFamilia: 14, superFamilia:1, key:"otrosCarnes", nombreProducto: "OTROS",writable:true},
      { espacios :[10], cartas: [1], idFamilia: 15, superFamilia:1, key:"lemonPie",nombreProducto: "LEMON PIE"},
      { espacios :[10], cartas: [1], idFamilia: 15, superFamilia:1, key:"brownie",nombreProducto: "BROWNIE"},
      { espacios :[10], cartas: [1], idFamilia: 15, superFamilia:1, key:"milhojasCaramelizado", nombreProducto: "MILHOJAS CARAM."},
      { espacios :[10], cartas: [1], idFamilia: 15, superFamilia:1, key:"bombonHelado", nombreProducto: "BOMBÓN HELADO"},
      { espacios :[10], cartas: [1], idFamilia: 15, superFamilia:1, key:"galaxiaDeChocolate", nombreProducto: "GALAXIA CHOCOLATE"},
      { espacios :[10], cartas: [1], idFamilia: 15, superFamilia:1, key:"tiramisu", nombreProducto: "TIRAMISÚ"},
      { espacios :[10], cartas: [1], idFamilia: 15, superFamilia:1, key:"sacher", nombreProducto: "SACHER"},
      { espacios :[10], cartas: [1], idFamilia: 15, superFamilia:1, key:"otrosPostres", nombreProducto: "OTROS", writable:true},
      // { espacios :[10], cartas: [1,2], idFamilia: 16, superFamilia:2, key:"vinasDelVeroMC", nombreProducto: "VIÑAS DEL VERO MACABEO-CHARDONNAY"},
      // { espacios :[10], cartas: [1,2], idFamilia: 16, superFamilia:2, key:"lausChardonnay", nombreProducto: "LAUS CHARDONNAY"},
      // { espacios :[10], cartas: [1,2], idFamilia: 16, superFamilia:2, key:"pirineos3404", nombreProducto: "PIRINEOS 3404"},
      // { espacios :[10], cartas: [1,2], idFamilia: 16, superFamilia:2, key:"lauraLalanne", nombreProducto: "LAURA LALANNE"},
      // { espacios :[10], cartas: [1,2], idFamilia: 16, superFamilia:2, key:"enate234Chardonnay", nombreProducto: "ENATE 234 CHARDONNAY"},
      // { espacios :[10], cartas: [1,2], idFamilia: 16, superFamilia:2, key:"vinasDelVeroGewurztraminer", nombreProducto: "VIÑAS DEL VERO GEWÜRZTRAMINER"},
      // { espacios :[10], cartas: [1,2], idFamilia: 17, superFamilia:2, key:"vinasDelVeroCrianza", nombreProducto: "VIÑAS DEL VERO CRIANZA"},
      // { espacios :[10], cartas: [1,2], idFamilia: 17, superFamilia:2, key:"lausCrianza", nombreProducto: "LAUS CRIANZA"},
      // { espacios :[10], cartas: [1,2], idFamilia: 17, superFamilia:2, key:"deBerozCrianza", nombreProducto: "DE BEROZ CRIANZA"},
      // { espacios :[10], cartas: [1,2], idFamilia: 17, superFamilia:2, key:"lalanneCrianza", nombreProducto: "LALANNE CRIANZA"},
      // { espacios :[10], cartas: [1,2], idFamilia: 17, superFamilia:2, key:"enateCrianza", nombreProducto: "ENATE CRIANZA"},
      // { espacios :[10], cartas: [1,2], idFamilia: 18, superFamilia:2, key:"cavaReyesAragon", nombreProducto: "CAVA REYES DE ARAGÓN BRUT"},
      // { espacios :[10], cartas: [1,2], idFamilia: 18, superFamilia:2, key:"freixenetVintageReservaBrut", nombreProducto: "FREIXENET VINTAGE RESERVA BRUT"},
      // { espacios :[10], cartas: [1,2], idFamilia: 18, superFamilia:2, key:"lucreciaLalanneBrut", nombreProducto: "LUCRECIA LALANNE BRUT"},
      // { espacios :[10], cartas: [1,2], idFamilia: 18, superFamilia:2, key:"agustiTorelloMataBrutNature", nombreProducto: "AGUSTÍ TORRELLÓ & MATA BRUT NATURE"},
      // { espacios :[10], cartas: [1,2], idFamilia: 18, superFamilia:2, key:"cavaJuveCampsResservaDeFamilia", nombreProducto: "CAVA JUVÉ & CAMPS RESERVA DE FAMILIA"},
      { espacios :[10], cartas: [2], idFamilia: 9, superFamilia:1, key:"miniPerdiciones", nombreProducto: "MINI PERDICIONES"},
      { espacios :[10], cartas: [2], idFamilia: 9, superFamilia:1, key:"otrosMomentoDulce", nombreProducto: "OTROS", writable:true},
      { espacios :[10], cartas: [1,2], idFamilia: 19, superFamilia:3, key:"recenaTradicional", nombreProducto: "TRADICIONAL"},
      { espacios :[10], cartas: [1,2], idFamilia: 19, superFamilia:3, key:"recenaEspecial", nombreProducto: "ESPECIAL"},
      { espacios :[10], cartas: [1,2], idFamilia: 19, superFamilia:3, key:"otrosRecena", nombreProducto: "OTROS",writable:true},
      // { espacios :[10], cartas: [1,2], idFamilia: 20, superFamilia:3, key:"licoresDeMesa", nombreProducto: "LICORES DE MESA"},
      // { espacios :[10], cartas: [1,2], idFamilia: 20, superFamilia:3, key:"barraLibre", nombreProducto: "BARRA LLIBRE"},
      // { espacios :[10], cartas: [1,2], idFamilia: 20, superFamilia:3, key:"otrosLicores", nombreProducto: "OTROS LICORES - DETALLAR"},
      // { espacios :[10], cartas: [2], idFamilia: 21, superFamilia:3, key:"modeloMasFarre", nombreProducto: "MODELO MAS FARRÉ"},
      // { espacios :[10], cartas: [2], idFamilia: 21, superFamilia:3, key:"tieneLogo", nombreProducto: "PAREJA TIENE LOGO"},
      // { espacios :[10], cartas: [2], idFamilia: 21, superFamilia:3, key:"tieneSeating", nombreProducto: "PAREJA APORTA SEATING"},
      // { espacios :[10], cartas: [2], idFamilia: 21, superFamilia:3, key:"encargaPareja", nombreProducto: "SE ENCARGA LA PAREJA"},
      // { espacios :[10], cartas: [2], idFamilia: 22, superFamilia:3, key:"decoracionFloral", nombreProducto: "DECORACION FLORAL"},
    ],
    pb:{}
  }),
  computed:{
    maxPages(){
      return 7
    }
  },
  methods: {
    prev(){
      this.page--
    },
    next(){
      // validacion campos obligatorios

      this.page++
    },
    send(){

    },
    open(pb) {
      this.dialog = true
      this.valid = true
      this.datos = Object.assign(this.datos, pb)
      this.pb = Object.assign(this.pb, pb.dosier)
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    agree() {
      this.resolve(this.pb)
      this.dialog = false
      this.page = 1
    },
    cancel() {
      this.resolve(false)
      this.dialog = false
      this.page = 1
    },
  },
  mounted(){
    this.productos.forEach((producto,i) => {
      producto.key = i
    });
  }
}
</script>

<style>

.sticky-actions-dark {
  position: sticky;
  bottom: 0;
  background: var(--v-secondary-base);
  z-index: 201;
}
.sticky-actions-light {
  position: sticky;
  bottom: 0;
  background: rgb(244,244,244);
  z-index: 201;
}
</style>