<template>
  <div v-if="!loading" class="pa-0">
    <v-card>
      <v-simple-table fixed-header dense>
        <thead>
          <tr>
            <th class="text-center">PAREJA</th>
            <th class="text-center">FECHA BODA</th>
            <th class="text-center">ADULTOS</th>
            <th class="text-center">ESPACIO</th>
            <th class="text-center">Nº PAX</th>
            <th class="text-center" style="width:50px;">DÍA PB</th>
            <th class="text-center">GENERAR URL</th>
            <th class="text-center">DOSIER PAREJA</th>
            <th class="text-center">GENERAR PB</th>
            <th class="text-center">PB MASFARRE</th>
            <th class="text-center">PPTO</th>
          </tr>
        </thead>
        <tbody>
          <tr @click="openPb(pb)" style="height: 50px;cursor:pointer" v-for="pb in pruebasFiltered" :key="pb.idEvento" :title="pb.datos.breveDato">
            <td>{{ pb.datos.novios ? (pb.datos.novios.startsWith("PB ") ? pb.datos.novios.slice(3) : pb.datos.novios) : '???' }}</td>
            <td>{{ pb.datos.fechaBodaReal ? new Date(pb.datos.fechaBodaReal).toLocaleDateString('esp',{ weekday:'short', day:'numeric' ,month:'short' }).toLocaleUpperCase('esp') : "" }}</td>
            <td>{{ pb.adultos || 0 }}</td>
            <td>{{ pb.nombreEspacio || '' }}</td>
            <td>{{ pb.datos.nAdultos }}</td>
            <td :style="'white-space:nowrap;' + (new Date(pb.datos.fechaInicioEvento) < new Date() ? 'background-color: var(--v-success-base)' : 'background-color: var(--v-warning-base)')">{{ pb.datos.fechaInicioEvento ? new Date(pb.datos.fechaInicioEvento).toLocaleDateString('esp',{ weekday:'short', day:'numeric' ,month:'short' }).toLocaleUpperCase('esp') : "" }}</td>
            <td><v-btn color="primary">Generar url</v-btn></td>
            <td><v-checkbox disabled></v-checkbox></td>
            <td><v-btn color="success" @click.stop="exportar(pb)"><v-icon>mdi-microsoft-excel</v-icon></v-btn></td>
            <td><v-checkbox disabled></v-checkbox></td>
            <td><v-btn disabled color="primary">Generar ppto</v-btn></td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-card>
    <ConfirmDialog ref="confirm" />
    <Dosier ref="dosier" />
    <!-- <div style="max-width: fit-content;position: fixed;right:20px; bottom: 20px;z-index:100000" bottom class="d-flex mt-2 ms-auto">
      <v-btn color="primary" fab @click="exportar" large class="me-4">
        <v-icon>mdi-microsoft-excel</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn color="primary" fab @click="savePlan" large>
        <v-icon>mdi-floppy</v-icon>
      </v-btn>
    </div> -->
  </div>
  <v-container v-else>
    <div style="width: 100%;height: 90vh;" class="d-flex align-center justify-center">
      <v-progress-circular
        size="250"
        indeterminate
        color="primary"
      >
        <h2>Cargando</h2>
      </v-progress-circular>
    </div>
  </v-container>
</template>

<script>
import FileSaver from 'file-saver';
import * as ExcelJS from "exceljs";

export default {
  components: {
    // Evento: () => import("../components/Evento.vue"),
    ConfirmDialog: () => import("@/components/ConfirmDialog.vue"),
    Dosier: () => import("../components/Dosier.vue"),
  },
  computed:{
    pruebasFiltered(){
      return this.pruebas
    },
  },
  data() {
    return {
      loading: true,
      pruebas: [],
    };
  },
  methods: {
    exportar(pb){
      const workbook = new ExcelJS.Workbook();
      const sheet = workbook.addWorksheet('PRESUPUESTO');
      var rows = [
        [null, "DATOS PRUEBA DE BODA"],
        [null, "DÍA PRUEBA DBODA"],
        [null, "NOMBRE PAREJA", pb.datos.novios || ''],
        [null, "BREVE DATO PAREJA", pb.datos.breveDato || ''],
        [null, "HORARIO", pb.datos.horario || ''],
        [null, "PERSONAS", pb.datos.pax || ''],
        [null, "SALÓN", pb.datos.salon || ''],
        [null, "ATIENDE", pb.datos.atiende || ''],
        [null, "ALERGIAS", pb.datos.alergias || ''],
        [null, "EXTRAS PRUEBA DE BODA (ANOTAR Y COBRAR)", pb.datos.extras || ''],
        [],
        [null, "PRUEBA DE MENÚ"],
      ]

      var paraEmpezar =[[null, 'PARA EMPEZAR']]
      if (pb.dosier.menuSalmorejo) paraEmpezar.push([null, "Salmorejo de tomate rosa y cerezas, concassé de piña y langostino"])
      if (pb.dosier.menuSopita) paraEmpezar.push([null, "Sopita fría de carabineros, vieiras a la plancha, nube de jamón ibérico"])
      if (pb.dosier.menuTartar) paraEmpezar.push([null, "Tartar de atún con guacamole y sopita de tomate km0"])

      if (paraEmpezar.length > 1) {
        rows.push(...paraEmpezar)
      }

      var primerPlato =[[null, 'PRIMER PLATO']]
      if (pb.dosier.menuEnsaladaLangostinos) primerPlato.push([null,"Ensalada de langostinos, escabeche oriental de vieiras y vinagreta de maracuyá"])
      if (pb.dosier.menuCannelloni) primerPlato.push([null,"Cannelloni de pasta fresca con presa ibérica, trompeta negra, rosignol y demiglacé"])
      if (pb.dosier.menuRavioli) primerPlato.push([null,"Ravioli de txangurro y carabineros con emulsión de gamba roja"])
      if (pb.dosier.menuCanelon) primerPlato.push([null,"Canelón de rape y langostinos con royal de cigalas"])
      if (pb.dosier.menuPulpo) primerPlato.push([null,"Pulpo braseado - cremoso de patata - alioli de pimentón - guacamole"])
      if (pb.dosier.menuMerluza) primerPlato.push([null,"Merluza con marinera de sepia y pulpo y gratén de boletus"])
      if (pb.dosier.menuBacalao) primerPlato.push([null,"Bacalao 70º con tradicional salsa verde, langostino y crujiente de gambas y almendras"])
      if (pb.dosier.menuLubina) primerPlato.push([null,"Lubina con ragout de ajos, ajetes, shitakes, trigueros y vieiras con suave alioli"])
      if (pb.dosier.menuRodaballo) primerPlato.push([null,"Rodaballo con chipirones en su tinta y muselina de gamba roja"])
      if (pb.dosier.menuEnsaladaMedioBogavante) primerPlato.push([null,"Ensalada de medio Bogavante gallego y su vinagreta de coral"])
      if (pb.dosier.menuEnsaladaBogavante) primerPlato.push([null,"Ensalada de bogavante gallego y tartar de cítricos"])
      if (pb.dosier.menuBogavante) primerPlato.push([null,"Bogavante gallego asado a la parrilla"])
      if (pb.dosier.menuLangosta) primerPlato.push([null,"Langosta imperial en dos texturas (media)"])
      if (primerPlato.length > 1) {
        rows.push(...primerPlato)
      }

      var sorbetes = [[null,"Orange Fantasy / Green Love / Tropic Passion / Pink&Prite / Fresas & Möet / Gewürztraminer"]]
      rows.push(...sorbetes)

      var carnes =[[null, 'CARNES']]
      if (pb.dosier.menuCostilla) carnes.push([null,"Costila ibérica de Jabugo ahumada y rustida con parmentier a la francesa"])
      if (pb.dosier.menuJarrete) carnes.push([null,"Jarrete de lechazo al homo, con romero y patata rota"])
      if (pb.dosier.menuPaletillaTernasco) carnes.push([null,"Paletila de ternasco asado al modo tradicional con patatas panadera"])
      if (pb.dosier.menuTernasco) carnes.push([null,"Ternasco glaseado a baja temperatura - patata trufada - boniato"])
      if (pb.dosier.menuSolomillo) carnes.push([null,"Solomillo de ternera - reducción de cabernet Sauvignon - tupinambo - manzana"])
      if (pb.dosier.menuSolomilloFoie) carnes.push([null,"Solomillo de ternera con escalopa de foile, cremoso de patata y oporto"])
      if (pb.dosier.menuLechazo) carnes.push([null,"Lechazo de Castita, pimientos de cristal y cebollitas glaseadas"])
      if (pb.dosier.menuPaletillaCabrito) carnes.push([null,"Paletilla de cabrito con patatas panadera, romero, ajo y perejil"])
      if (carnes.length > 1) {
        rows.push(...carnes)
      }

      rows.push([null,'POSTRES'])
      rows.push([null,'Lemon Pie & merengue'])
      rows.push([null,'Brownie de chocolate 70% con crema de café'])
      rows.push([null,'Milhojas caramelizado y emulsión de crema pastelera'])
      rows.push([null,'Bombón helado de chocolate y sus satélites'])
      rows.push([null,'Versión del tiramisú'])
      rows.push([null,'Sacher crujiente con crema de naranja sanguina'])
      rows.push([null,'Misterio de texturas de chocolate'])

      sheet.addRows(rows)

      workbook.xlsx.writeBuffer()
      .then(buffer => FileSaver.saveAs(new Blob([buffer]), `${"PRESUPUESTO - " + (new Date().toLocaleDateString('default', { weekday: 'short' }).toUpperCase() + ' - ' + (('0' + new Date().getDate()).slice(-2)) + (new Date().toLocaleString('default', { month: 'short' }).toUpperCase())) + " - " + new Date().getFullYear()}.xlsx`))
      .catch(err => console.log('Error writing excel export', err))

      //datos boda

      //estilos
      alert("ExcelJS error. <Styles> buffer not properly closed.")
    },
    async openPb(pb){
      const data = await this.$refs.dosier.open(pb)
      if (!data) return
      pb.dosier = data
      try{
        await axios({
          method: "POST",
          url: `/pruebas_boda/${pb.idEvento}`,
          data: pb.dosier
        });
        this.$root.$emit("snack", "Se han guardado los cambios correctamente.")
      }
      catch (e){
        this.$root.$emit("snack", "Error al guardar los cambios.")
        console.log(e);
      }
    },
    async getPruebas() {
      const { data } = await axios({
        url: `/pruebas_boda/${new Date().getFullYear()}`,
      });
      this.pruebas = data;
      console.log(this.pruebas);
    },
  },
  async mounted() {
    this.loading = true
    
    this.productos.forEach(producto => {
      this.productos[producto].key = this.productos.entries
    });
    await Promise.all([
      this.getPruebas()
    ])
    this.loading = false
  },
};
</script>

<style scoped>
.stick {
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: white;
}
.diagonal-header {
  margin: 0;
  max-width: 48px !important;
  height: 165px !important;
  white-space: nowrap;
  vertical-align: bottom;
  text-align: left;
  overflow: visible;
}
.diagonal-text {
  transform: rotate(-90deg);
  transform-origin: center center;
  text-align: left;
  white-space: nowrap;
}
.diagonal-familia {
  max-height: 30px;
  transform: rotate(-90deg);
  transform-origin: center center;
  text-align: left;
  white-space: nowrap;
}
.item:hover{
  z-index:1000;
  border: none !important;
  -webkit-box-shadow: inset 0px 0px 5px 1px rgba(0,0,0,0.75) !important;
  -moz-box-shadow: inset 0px 0px 5px 1px rgba(0,0,0,0.75) !important;
  box-shadow: inset 0px 0px 5px 1px rgba(0,0,0,0.75) !important;
  cursor:pointer
}
.itemDark:hover{
  z-index:1000;
  border: none !important;
  -webkit-box-shadow: inset 0px 0px 5px 1px rgba(255,255,255,0.75) !important;
  -moz-box-shadow: inset 0px 0px 5px 1px rgba(255,255,255,0.75) !important;
  box-shadow: inset 0px 0px 5px 1px rgba(255,255,255,0.75) !important;
  cursor:pointer
}
.v-input--checkbox{
  margin-top: 0 !important;
}
.v-input--checkbox >>> .v-input--selection-controls__input{
  margin: 0 !important;
}
tbody tr:hover {
  background-color: transparent !important;
}
/* .diagonal-header::before {
  display: block;
  transform: rotate(45deg);
  transform-origin: right bottom;
  content:'';
  width: 0px !important;
  height: 100px !important;
  border: 1px solid black; 
} */
</style>

<style>
.v-data-table__wrapper{
  overflow-x: auto !important;
  overflow-y: auto !important;
  height: 99vh !important;
}

.v-data-table__wrapper thead{
  position: sticky;
  top: 0;
  z-index: 10;
}
</style>